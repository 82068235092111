import {
  defaultServerSessionAnalytics,
  MinimalSessionApiPayload,
  ServerSessionAnalytics,
  User,
} from "@hornet-web-react/core/types/session"
import { equals } from "ramda"
import { z, ZodError } from "zod"
import QuickiesMemberModel, {
  QuickiesMemberBaseApiPayload,
} from "./quickies-member.model"
import {
  Either,
  isStrictNever,
  makeLeft,
  makeRight,
} from "@hornet-web-react/core/utils"
import { QuickiesProfileId } from "@hornet-web-react/core/types"
import { InboxType } from "@hornet-web-react/chat/models/messages-conversation.model"
import { DickVerificationState, QuickiesSessionFeatureFlag } from "./types"
import { EntitlementInterface } from "@hornet-web-react/core/types/entitlements"
import {
  createEntitlement,
  filterEntitlementsByExpiryDate,
} from "@hornet-web-react/core/models/entitlement-model"
import { ApiEntitlement } from "@hornet-web-react/core/types/api"

const QuickiesSessionTotalsApiPayload = z.object({
  unread_messages: z.number(),
})
export type QuickiesSessionTotalsApiPayload = z.infer<
  typeof QuickiesSessionTotalsApiPayload
>

const QuickiesSessionSettingsApiPayload = z.object({
  // features: z.array(z.string()),
  default_inbox: InboxType,
  analytics: ServerSessionAnalytics.optional(),
})
export type QuickiesSessionSettingsApiPayload = z.infer<
  typeof QuickiesSessionSettingsApiPayload
>

const QuickiesSessionAccountApiPayload =
  MinimalSessionApiPayload.shape.session.shape.account.merge(
    z.object({
      sanitized_username: z.string(),
      username_claimed: z.boolean(),
      email_verified: z.boolean(),
      public: z.boolean(),
      email_opt_out: z.boolean(),
      phone_number: z.string().nullable(),
    })
  )
export type QuickiesSessionAccountApiPayload = z.infer<
  typeof QuickiesSessionAccountApiPayload
>

const QuickiesMyProfileApiPayload = z.object({
  quickies_profile: QuickiesMemberBaseApiPayload.extend({
    active: z.boolean(),
    location_randomization_amount: z.number(),
    age_shown: z.boolean(),
    endowment_shown: z.boolean(),
    height_shown: z.boolean(),
    weight_shown: z.boolean(),
    body_type_shown: z.boolean(),
    sexuality_attitude_shown: z.boolean(),
    sexuality_expression_shown: z.boolean(),
    sexuality_spectrum_shown: z.boolean(),
    scene_locations_shown: z.boolean(),
    scene_into_publics_shown: z.boolean(),
    scene_looking_fors_shown: z.boolean(),
    scene_fetishes_shown: z.boolean(),
    scene_kinks_shown: z.boolean(),
    scene_intos_shown: z.boolean(),
    scene_interactions_shown: z.boolean(),
    health_protection_shown: z.boolean(),
    health_hiv_status_shown: z.boolean(),
    health_hiv_tested_at_shown: z.boolean(),
    health_std_tested_at_shown: z.boolean(),
    health_safeguards_shown: z.boolean(),
    dick_verification_state: DickVerificationState.nullable(),
  }),
})
export type QuickiesMyProfileApiPayload = z.infer<
  typeof QuickiesMyProfileApiPayload
>

export const QuickiesSessionStatusInfo = z.object({
  hangouts_dot_last_set_at: z.coerce.date(),
  hangouts_dot_last_seen_at: z.coerce.date(),
})
export type QuickiesSessionStatusInfo = z.infer<
  typeof QuickiesSessionStatusInfo
>

const QuickiesSessionApiPayload = z.object({
  session: MinimalSessionApiPayload.shape.session.merge(
    z.object({
      account: QuickiesSessionAccountApiPayload,
      totals: QuickiesSessionTotalsApiPayload,
      settings: QuickiesSessionSettingsApiPayload.optional(),
      quickies: QuickiesMyProfileApiPayload.extend({
        per_page: z.number(),
        has_debug: z.boolean().optional(),
        session_status_info: QuickiesSessionStatusInfo,
      }),
      entitlements: z.array(ApiEntitlement).optional(),
    })
  ),
})
export type QuickiesSessionApiPayload = z.infer<
  typeof QuickiesSessionApiPayload
>

export interface QuickiesSessionInterface {
  hasAwardsEnabled: boolean
  hasAudioMessagesEnabled: boolean
  hasReadReceiptsEnabled: boolean
  hasDeletedMessageExperimentEnabled: boolean
  profile: QuickiesMyProfileModel
  account: QuickiesSessionAccountApiPayload
  currentUser: User | undefined
  mapPerPage: number
  isSameUser: (user: User) => boolean
  isAccountEmailVerified: boolean
  isAnonymous: boolean
  hasAccountPhoneNumber: boolean
  defaultInbox: InboxType
  isEligibleForDickVerification: boolean
  isPendingDickVerification: boolean
  hasDebug: boolean
  dickVerificationState: DickVerificationState
  entitlements: EntitlementInterface[]
  serverSessionAnalytics: ServerSessionAnalytics
  hasGroupsNotificationUnreadDot: boolean

  serialize(): QuickiesSessionApiPayload

  updateProfile(profile: QuickiesMyProfileModel): QuickiesSessionInterface

  setDefaultInbox(selectedInbox: InboxType): QuickiesSessionInterface

  updateDickVerificationState(
    state: DickVerificationState
  ): QuickiesSessionInterface

  // TODO: refactor this into `use-quickies-session-feature-flags` hook so
  // it's much easier to find
  isFeatureEnabled(feature: QuickiesSessionFeatureFlag): boolean
}

export function createQuickiesSessionModel(
  data?: QuickiesSessionApiPayload
): Either<ZodError, QuickiesSessionInterface> {
  const sessionModel = QuickiesSessionApiPayload.safeParse(data)
  if (sessionModel.success) {
    return makeRight(new QuickiesSessionModel(sessionModel.data))
  }

  return makeLeft(sessionModel.error)
}

class QuickiesSessionModel implements QuickiesSessionInterface {
  private readonly session: QuickiesSessionApiPayload["session"]
  readonly profile: QuickiesMyProfileModel

  constructor(props: QuickiesSessionApiPayload) {
    this.session = props.session
    this.profile = new QuickiesMyProfileModel(
      QuickiesMyProfileApiPayload.parse(props.session.quickies)
    )
  }

  get currentUser(): User | undefined {
    const user = User.safeParse({
      isAuthenticated: true,
      currentUser: {
        accessToken: String(this.session.access_token),
        accessTokenValidUntil: new Date(this.session.valid_until).toISOString(),
        username: String(this.session.account.username),
        email: String(this.session.account.email) || "no_email",
        isNew: false, // TODO?: Boolean(this.session.account.new_user),
        hasPremium: false, // TODO?: Boolean(this.session.account.premium?.active),
        profileId: QuickiesProfileId.parse(
          String(this.session.quickies.quickies_profile.id)
        ),
        isImperialUnitOfMeasure: false,
        communityToken: null,
      },
    })

    return user.success ? user.data : undefined
  }

  get hasReadReceiptsEnabled() {
    // used to be `this.session.settings.features.includes("read_receipts")`
    return true
  }

  get hasAwardsEnabled() {
    // return this.session.settings.features.includes("award_audio_messages")
    return false
  }

  get hasAudioMessagesEnabled() {
    // return this.session.settings.features.includes("award_audio_messages")
    return false
  }

  get isAccountEmailVerified() {
    return this.session.account.email_verified
  }

  get hasDeletedMessageExperimentEnabled() {
    // return this.session.settings.features.includes("message_delete")
    return false
  }

  get mapPerPage() {
    return this.session.quickies.per_page
  }

  get account() {
    return this.session.account
  }

  get hasAccountPhoneNumber() {
    return !!this.session.account.phone_number
  }

  get isAnonymous() {
    return this.session.quickies.quickies_profile.anonymous === true
  }

  get defaultInbox() {
    return this.session.settings?.default_inbox || InboxType.enum.all
  }

  get dickVerificationState(): DickVerificationState {
    if (
      this.session.quickies.quickies_profile.dick_verification_state === null
    ) {
      return DickVerificationState.enum.none
    }

    return this.session.quickies.quickies_profile.dick_verification_state
  }

  get isEligibleForDickVerification(): boolean {
    if (!this.profile.hasCustomProfilePhoto) {
      return false
    }

    switch (this.dickVerificationState) {
      case DickVerificationState.enum.none:
      case DickVerificationState.enum.rejected:
      case DickVerificationState.enum.photos_removed:
        return true
      case DickVerificationState.enum.pending:
      case DickVerificationState.enum.accepted:
        return false
      default:
        isStrictNever(this.dickVerificationState)
        return false
    }
  }

  get isPendingDickVerification() {
    return this.dickVerificationState === DickVerificationState.enum.pending
  }

  get hasDebug() {
    return this.session.quickies.has_debug === true
  }

  get entitlements(): EntitlementInterface[] {
    return (this.session.entitlements || [])
      .filter(filterEntitlementsByExpiryDate)
      .map(createEntitlement)
  }

  get serverSessionAnalytics(): ServerSessionAnalytics {
    try {
      return ServerSessionAnalytics.parse(this.session.settings?.analytics)
    } catch (err) {
      console.error(err)
      return defaultServerSessionAnalytics
    }
  }

  get hasGroupsNotificationUnreadDot() {
    return (
      this.session.quickies.session_status_info.hangouts_dot_last_set_at >
      this.session.quickies.session_status_info.hangouts_dot_last_seen_at
    )
  }

  isSameUser(user: User) {
    return equals(user, this.currentUser)
  }

  serialize() {
    return {
      session: {
        ...this.session,
        quickies: {
          per_page: this.session.quickies.per_page,
          has_debug: this.session.quickies.has_debug || false,
          session_status_info: this.session.quickies.session_status_info,
          ...this.profile.serializeProfile(),
        },
      },
    }
  }

  updateProfile(profile: QuickiesMyProfileModel) {
    return new QuickiesSessionModel({
      session: {
        ...this.session,
        quickies: {
          ...this.session.quickies,
          ...profile.serializeProfile(),
        },
      },
    })
  }

  setDefaultInbox(selectedInbox: InboxType): QuickiesSessionInterface {
    return new QuickiesSessionModel({
      session: {
        ...this.session,
        settings: {
          ...this.session.settings,
          default_inbox: selectedInbox,
        },
      },
    })
  }

  updateDickVerificationState(state: DickVerificationState) {
    return new QuickiesSessionModel({
      session: {
        ...this.session,
        quickies: {
          ...this.session.quickies,
          quickies_profile: {
            ...this.session.quickies.quickies_profile,
            dick_verification_state:
              state === DickVerificationState.enum.none ? null : state,
          },
        },
      },
    })
  }

  isFeatureEnabled(feature: QuickiesSessionFeatureFlag): boolean {
    // this is here because it's assumed that in the future, this config
    // would come from a session.settings.features or something like that
    switch (feature) {
      case QuickiesSessionFeatureFlag.enum.dick_verification:
        return true

      default:
        isStrictNever(feature)
        return false
    }
  }
}

export class QuickiesMyProfileModel extends QuickiesMemberModel {
  // use in Edit Profile scenarios
  private readonly profile: QuickiesMyProfileApiPayload["quickies_profile"]

  constructor(payload: QuickiesMyProfileApiPayload) {
    super({
      quickies_member: {
        ...payload.quickies_profile,
        online: false,
        last_online: null,
      },
    })
    this.profile = payload.quickies_profile
  }

  get locationRandomizationAmount(): number {
    return this.profile.location_randomization_amount
  }

  get ageShown(): boolean {
    return this.profile.age_shown
  }

  get heightShown(): boolean {
    return this.profile.height_shown
  }

  get weightShown(): boolean {
    return this.profile.weight_shown
  }

  get endowmentShown(): boolean {
    return this.profile.endowment_shown
  }

  get bodyTypeShown() {
    return this.profile.body_type_shown
  }

  get sexualityAttitudeShown() {
    return this.profile.sexuality_attitude_shown
  }

  get sexualityExpressionShown() {
    return this.profile.sexuality_expression_shown
  }

  get sexualitySpectrumShown() {
    return this.profile.sexuality_spectrum_shown
  }

  get sceneLocationsShown() {
    return this.profile.scene_locations_shown
  }

  get sceneIntoPublicsShown() {
    return this.profile.scene_into_publics_shown
  }

  get sceneLookingForsShown() {
    return this.profile.scene_looking_fors_shown
  }

  get sceneFetishesShown() {
    return this.profile.scene_fetishes_shown
  }

  get sceneKinksShown() {
    return this.profile.scene_kinks_shown
  }

  get sceneIntosShown() {
    return this.profile.scene_intos_shown
  }

  get sceneInteractionsShown() {
    return this.profile.scene_interactions_shown
  }

  get healthProtectionShown() {
    return this.profile.health_protection_shown
  }

  get healthHivStatusShown() {
    return this.profile.health_hiv_status_shown
  }

  get healthHivTestedAtShown() {
    return this.profile.health_hiv_tested_at_shown
  }

  get healthStdTestedAtShown() {
    return this.profile.health_std_tested_at_shown
  }

  get healthSafeguardsShown() {
    return this.profile.health_safeguards_shown
  }

  get isActive() {
    return this.profile.active
  }

  serializeProfile(): QuickiesMyProfileApiPayload {
    return {
      quickies_profile: {
        ...this.profile,
        quickies_photos: this.photos.map((p) => p.serialize()),
      },
    }
  }

  usePhotosFromMember(member: QuickiesMemberModel) {
    return new QuickiesMyProfileModel({
      quickies_profile: {
        ...this.profile,
        quickies_photos: [...member.photos.map((p) => p.serialize())],
      },
    })
  }
}
