import styled, { css } from "styled-components"
import {
  useSessionDevice,
  useSessionEnvironment,
  useSessionRouteTransition,
  useSessionUser,
} from "@hornet-web-react/core/contexts/session"
import { version, environment } from "../../config"
import { ReactNode, useEffect, useRef } from "react"
import PageLoader from "@hornet-web-react/core/components/UI/PageLoader"
import { useRouter } from "next/router"
import { useQuickiesIpLocation } from "../../hooks/use-quickies-ip-location"
import { useQuickiesRealtimeSession } from "../../hooks/use-quickies-realtime-session"
import { useQuickiesSession } from "../../hooks/use-quickies-session"
import { debug } from "@hornet-web-react/core/utils"
import { useQuickiesRealtimeProfiles } from "../../hooks/use-quickies-realtime-profiles"
import { useEvent } from "react-use"
import { useAppContainerContextState } from "../../contexts/app-container-context"
import FeedbackButton from "./FeedbackButton"
import {
  countryCheckIgnorePathnames,
  getLocaleForCountry,
} from "../../utils/is-country-code-allowed"
import { useQuickiesCookieConsent } from "../../hooks/use-quickies-cookie-consent"
import { useQuickiesNavigation } from "../../hooks/use-quickies-navigation"
import { useQuickiesLogout } from "../../hooks/use-quickies-logout"
import { UNKNOWN_USER_COUNTRY } from "@hornet-web-react/core/utils/constants"

type AppContainerProps = {
  children: ReactNode
  hasTopNavBar: boolean
  hasBottomNavBar: boolean
}

const cookieConsentIgnoreComponentNames = [
  // these do not need cookie consent
  ...countryCheckIgnorePathnames,
  // following have custom triggers via CTA buttons
  "/",
  "/accept-invite",
  "/invites/accept/[accept_invite_code]",
  "/invites/signup/[signup_invite_code]",
]

export default function AppContainer({
  children,
  hasTopNavBar,
  hasBottomNavBar,
}: AppContainerProps) {
  const { isAuthenticated } = useSessionUser()
  const { isInApp } = useSessionEnvironment()
  const { locale, country } = useSessionDevice()
  const { isLoading, handleCookieConsentModal } = useQuickiesCookieConsent()
  const { isTransitioning } = useSessionRouteTransition()
  const router = useRouter()
  const { isAllowed } = useQuickiesIpLocation()
  const { markAccountAsVerified } = useQuickiesSession()
  const { markAsOnline, markAsOffline } = useQuickiesRealtimeProfiles()
  const { isSafeBottomPaddingDisabled } = useAppContainerContextState()
  const { routeForUnavailable } = useQuickiesNavigation()
  const { logoutAction } = useQuickiesLogout()
  const { navigateToUnavailable } = useQuickiesNavigation()
  const hasNavigatedToNewCountryLocale = useRef(false)

  const debugString = [version, locale, environment].join(";")

  const { subscribe: subscribeToSession, unsubscribe: unsubscribeFromSession } =
    useQuickiesRealtimeSession({ markAccountAsVerified })

  const isCookieConsentIgnored = cookieConsentIgnoreComponentNames.includes(
    router.pathname
  )

  const isCountryCheckIgnored = countryCheckIgnorePathnames.includes(
    router.pathname
  )

  // websocket subscriptions to session
  // mark as online/offline
  useEffect(() => {
    debug(
      "AppContainer: subscribeToSession, isAuthenticated: " +
        (isAuthenticated ? "yes" : "no")
    )

    if (!isAuthenticated) {
      return
    }

    markAsOnline()
    subscribeToSession()

    // mark as online before leaving
    const markAsOfflineBeforeLeaving = () => markAsOffline()
    window.addEventListener("beforeunload", markAsOfflineBeforeLeaving)

    return () => {
      window.removeEventListener("beforeunload", markAsOfflineBeforeLeaving)
      unsubscribeFromSession()
    }
  }, [
    isAuthenticated,
    markAsOffline,
    markAsOnline,
    subscribeToSession,
    unsubscribeFromSession,
  ])

  useEffect(() => {
    if (!isCookieConsentIgnored && !isInApp && !isLoading) {
      void handleCookieConsentModal()
    }
  }, [isLoading, isInApp, isCookieConsentIgnored, handleCookieConsentModal])

  // check country and redirect to uh-oh page if not allowed
  useEffect(() => {
    // we're using the same flag as for cookie consent to avoid redirect loops
    // and unnecessary redirects when user maybe just wants to read a page
    if (!isAllowed && !isTransitioning && !isCountryCheckIgnored) {
      void logoutAction(routeForUnavailable)
    }
  }, [
    isAllowed,
    isTransitioning,
    router,
    isCountryCheckIgnored,
    logoutAction,
    routeForUnavailable,
  ])

  // if the country changes, we may need to change locale as well
  // this needs to happen only once, it's a brittle connection between
  // `useQuickiesIpLocation` which triggers only once
  useEffect(() => {
    const localeForCurrentCountry = getLocaleForCountry(country)

    if (
      isAllowed &&
      country &&
      country !== UNKNOWN_USER_COUNTRY &&
      locale !== localeForCurrentCountry &&
      !hasNavigatedToNewCountryLocale.current
    ) {
      debug(`AppContainer: changing locale to ${localeForCurrentCountry}`)

      // prevent an infinite redirect loop
      hasNavigatedToNewCountryLocale.current = true

      // replace so it doesn't even go into history
      void router.replace(
        {
          pathname: router.pathname,
          query: router.query,
        },
        router.asPath,
        { locale: localeForCurrentCountry }
      )
    }
  }, [country, isAllowed, locale, router])

  // also mark as online/offline when document visibility changes (mainly for PWA)
  useEvent("visibilitychange", () => {
    document.visibilityState === "visible" ? markAsOnline() : markAsOffline()
  })

  return (
    <Wrapper
      isInApp={isInApp}
      hasTopNavBar={hasTopNavBar}
      hasBottomNavBar={hasBottomNavBar}
      isSafeBottomPaddingDisabled={isSafeBottomPaddingDisabled}
    >
      <Main>
        <PageLoader />
        {children}
      </Main>

      <DebugPanel id="dbg" aria-hidden="true">
        {debugString}
      </DebugPanel>

      <FeedbackButton />
    </Wrapper>
  )
}

const isInAppStyle = css`
  background: ${({ theme }) => theme.color.bg.light03};
  padding-top: 0;
`

const Wrapper = styled.div<{
  isInApp: boolean
  hasTopNavBar: boolean
  hasBottomNavBar: boolean
  isSafeBottomPaddingDisabled: boolean
}>`
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  overflow-x: hidden; // just a safety catch against overflowing content
  padding-top: ${({ theme, hasTopNavBar }) =>
    hasTopNavBar ? theme.height.topNavBar : 0};
  background: ${({ theme }) => theme.color.bg.light01};
  ${(props) => props.isInApp && isInAppStyle};
  transition: padding-bottom 250ms ease-in-out;

  // Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
  @supports (padding-bottom: constant(safe-area-inset-bottom)) {
    --safe-area-inset-bottom: ${({ isSafeBottomPaddingDisabled }) =>
      isSafeBottomPaddingDisabled ? "0px" : "constant(safe-area-inset-bottom)"};
    padding-bottom: calc(
      ${({ hasBottomNavBar }) => (hasBottomNavBar ? 50 : 0)}px +
        var(--safe-area-inset-bottom)
    );
  }
  @supports (padding-top: constant(safe-area-inset-top)) {
    --safe-area-inset-top: constant(safe-area-inset-top);
    padding-top: calc(
      ${({ theme, hasTopNavBar }) =>
          hasTopNavBar ? theme.height.topNavBar : "0px"} +
        var(--safe-area-inset-top)
    );
  }

  // Browsers which fully support CSS Environment variables (iOS 11.2+).
  @supports (padding-bottom: env(safe-area-inset-bottom)) {
    --safe-area-inset-bottom: ${({ isSafeBottomPaddingDisabled }) =>
      isSafeBottomPaddingDisabled ? "0px" : "env(safe-area-inset-bottom)"};
    padding-bottom: calc(
      ${({ hasBottomNavBar }) => (hasBottomNavBar ? 50 : 0)}px +
        var(--safe-area-inset-bottom)
    );
  }
  @supports (padding-top: env(safe-area-inset-top)) {
    --safe-area-inset-top: env(safe-area-inset-top);
    padding-top: calc(
      ${({ theme, hasTopNavBar }) =>
          hasTopNavBar ? theme.height.topNavBar : "0px"} +
        var(--safe-area-inset-top)
    );
  }

  @media screen and ${({ theme }) =>
      theme.breakpoints.mediaQueryHasVisibleLeftPanel} {
    @supports (padding-bottom: constant(safe-area-inset-bottom)) {
      --safe-area-inset-bottom: ${({ isSafeBottomPaddingDisabled }) =>
        isSafeBottomPaddingDisabled
          ? "0px"
          : "constant(safe-area-inset-bottom)"};
      padding-bottom: var(--safe-area-inset-bottom);
    }

    // Browsers which fully support CSS Environment variables (iOS 11.2+).
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
      --safe-area-inset-bottom: ${({ isSafeBottomPaddingDisabled }) =>
        isSafeBottomPaddingDisabled ? "0px" : "env(safe-area-inset-bottom)"};
      padding-bottom: var(--safe-area-inset-bottom);
    }
  }
`

const Main = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`

const DebugPanel = styled.div`
  display: none;
`
