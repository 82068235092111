import BaseTrackEvent from "@hornet-web-react/core/models/track-event"

class LoginAnalyticsEvent extends BaseTrackEvent {
  static welcomeTapOnForgotPassword() {
    return new LoginAnalyticsEvent("Welcome_tapOnForgotPassword")
  }

  static welcomeTapOnLogin(
    provider: UserSignedUpProvider,
    origin: UserSignedUpOrigin,
    shareId?: string
  ) {
    const baseEvent = new LoginAnalyticsEvent("Welcome_tapOnLogin", [
      { key: "provider", string_value: provider },
      { key: "origin", string_value: origin },
    ])

    if (!shareId) {
      return baseEvent
    }

    return baseEvent.addParams([{ key: "ref_id", string_value: shareId }])
  }

  static welcomeTapOnEnterMap() {
    return new LoginAnalyticsEvent("Welcome_tapOnEnterMap")
  }

  static userLoggedIn(
    provider: UserSignedUpProvider,
    origin: UserSignedUpOrigin
  ) {
    return new LoginAnalyticsEvent("User_loggedIn", [
      { key: "provider", string_value: provider },
      { key: "origin", string_value: origin },
    ])
  }

  static userLoginFailed(
    provider: UserSignedUpProvider,
    origin: UserSignedUpOrigin
  ) {
    return new LoginAnalyticsEvent("User_loginFailed", [
      { key: "provider", string_value: provider },
      { key: "origin", string_value: origin },
    ])
  }

  static userSignedUp(
    provider: UserSignedUpProvider,
    origin: UserSignedUpOrigin
  ) {
    return new LoginAnalyticsEvent("User_signedUp", [
      { key: "provider", string_value: provider },
      { key: "origin", string_value: origin },
    ])
  }

  static userSignUpFailed(
    provider: UserSignedUpProvider,
    origin: UserSignedUpOrigin
  ) {
    return new LoginAnalyticsEvent("User_signUpFailed", [
      { key: "provider", string_value: provider },
      { key: "origin", string_value: origin },
    ])
  }

  static userActivatedHornetX(origin: UserSignedUpOrigin) {
    return new LoginAnalyticsEvent("User_activatedHornetX", [
      { key: "origin", string_value: origin },
    ])
  }

  static welcomeTapOnCreateAccount(
    provider: UserSignedUpProvider,
    origin: UserSignedUpOrigin
  ) {
    return new LoginAnalyticsEvent("Welcome_tapOnCreateAccount", [
      { key: "provider", string_value: provider },
      { key: "origin", string_value: origin },
    ])
  }
}

export enum UserSignedUpProvider {
  Email = "email",
  Facebook = "facebook",
  Google = "google",
  QuickiesUdid = "quickies_udid",
  Hornet = "hornet",
}

export enum UserSignedUpOrigin {
  Homepage = "homepage",
  Onboarding = "onboarding",
  AcceptInvite = "accept_invite",
  AcceptInviteWithInviteCode = "accept_invite_with_invite_code",
  LandingUkr = "landing_ukr",
  LandingGer = "landing_ger",
  LandingTur = "landing_tur",
  LandingTha = "landing_tha",
  LandingTwn = "landing_twn",
  LandingIdn = "landing_idn",
}

export default LoginAnalyticsEvent
