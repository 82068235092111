import { FC } from "react"
import TextButton from "@hornet-web-react/core/components/UI/TextButton"
import QuickiesAnalyticsEvent from "../../models/quickies-analytics-event"
import NiceModal from "@ebay/nice-modal-react"
import { UserSignedUpOrigin } from "@hornet-web-react/login/models/login-analytics-event"
import useTranslation from "next-translate/useTranslation"
import { useQuickiesCookieConsent } from "../../hooks/use-quickies-cookie-consent"
import { useEventTrackerService } from "../../hooks/use-event-tracker-service"
import { useEffectOnce } from "react-use"

type G7SignupButtonProps = {
  redirectTo: string | null
  userSignedUpOrigin: UserSignedUpOrigin
  size?: "default" | "small"
}

const i18nKey = `quickies:components.layout.g7_signup_button`

const G7SignupButton: FC<G7SignupButtonProps> = ({
  redirectTo,
  userSignedUpOrigin,
  size = "default",
}) => {
  const { t } = useTranslation()
  const { handleCookieConsentModal } = useQuickiesCookieConsent()
  const { reportEvent } = useEventTrackerService()

  useEffectOnce(() => {
    // preload the modal
    if ("requestIdleCallback" in window) {
      window.requestIdleCallback(async () => import("../Modals/SignupG7Modal"))
    }
  })

  const handleSignupOnClick = async () => {
    void reportEvent(QuickiesAnalyticsEvent.landingTapOnSignupCta())

    await handleCookieConsentModal()

    const SignupG7Modal = (await import("../Modals/SignupG7Modal")).default
    void NiceModal.show(SignupG7Modal, {
      redirectTo,
      userSignedUpOrigin,
    })
  }

  return (
    <TextButton
      size={size}
      primary={true}
      onClick={() => handleSignupOnClick()}
    >
      {t(`${i18nKey}.label`)}
    </TextButton>
  )
}

export default G7SignupButton
