import { useQuickiesSession } from "./use-quickies-session"
import { useCallback, useMemo } from "react"
import { getQuickiesEntitlementStateFromSessionEntitlements } from "../utils/get-quickies-entitlement-state-from-session-entitlements"
import { QuickiesEntitlementState } from "../models/types"
import { EntitlementInterface } from "@hornet-web-react/core/types/entitlements"
import NiceModal from "@ebay/nice-modal-react"

type UseQuickiesPaywallResult = {
  reloadQuickiesSession: () => Promise<unknown>
  state: QuickiesEntitlementState
  entitlement: EntitlementInterface | undefined
  isMapPaywalled: boolean
  isChatPaywalled: boolean
  isGroupsPaywalled: boolean
  isDickVerificationPaywalled: boolean
  showPurchaseSuccessModal: () => void
}

export const useQuickiesPaywall = (): UseQuickiesPaywallResult => {
  const { data, mutate } = useQuickiesSession()

  const [entitlementState, entitlement] = useMemo(
    () =>
      getQuickiesEntitlementStateFromSessionEntitlements(data?.entitlements),
    [data]
  )

  const state = entitlementState

  return {
    reloadQuickiesSession: mutate,

    state,

    entitlement,

    isMapPaywalled: state === QuickiesEntitlementState.enum.freemium,
    isChatPaywalled: state === QuickiesEntitlementState.enum.freemium,
    isGroupsPaywalled: state === QuickiesEntitlementState.enum.freemium,
    isDickVerificationPaywalled:
      state === QuickiesEntitlementState.enum.freemium,

    showPurchaseSuccessModal: useCallback(async () => {
      const QuickiesShopPurchaseSuccessModal = (
        await import("../components/Modals/QuickiesShopPurchaseSuccessModal")
      ).default

      void NiceModal.show(QuickiesShopPurchaseSuccessModal)
    }, []),
  }
}
