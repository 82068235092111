import useTranslation from "next-translate/useTranslation"
import Modal from "./Modal"
import TextButton from "../UI/TextButton"
import styled from "styled-components"
import NiceModal from "@ebay/nice-modal-react"
import { ReactNode } from "react"

const ModalHeading = styled.div`
  ${({ theme }) => theme.font.bold.body};
`

const ModalText = styled.div`
  ${({ theme }) => theme.font.regular.subheadline};
`

type FlashMessageModalProps = {
  text: string | ReactNode
  heading?: string
  okButtonLabel?: string
}

const StyledModal = styled(Modal)`
  min-width: 0;
  max-width: ${({ theme }) => theme.width.containerMaxWidthMobile};
`

const FlashMessageModal = NiceModal.create(
  ({ heading, text, okButtonLabel }: FlashMessageModalProps) => {
    const { t } = useTranslation()
    const modal = NiceModal.useModal()

    const handleConfirmOnClick = () => {
      modal.resolve()
      void modal.hide()
    }

    return (
      <StyledModal
        open={modal.visible}
        locked={false}
        onClose={modal.hide}
        isFullscreen={false}
        hasCloseButton={false}
        actions={
          <TextButton
            size={"small"}
            primary={true}
            onClick={handleConfirmOnClick}
          >
            {okButtonLabel ||
              t("core:components.modals.flash_message_modal.buttons.ok")}
          </TextButton>
        }
      >
        {heading && <ModalHeading>{heading}</ModalHeading>}
        <ModalText>{text}</ModalText>
      </StyledModal>
    )
  }
)

export default FlashMessageModal
