import Link from "next/link"
import useTranslation from "next-translate/useTranslation"
import styled, { useTheme } from "styled-components"
import Container from "./Container"
import QuickiesLogo from "./QuickiesLogo"
import { ReactNode, useEffect, useState } from "react"
import {
  useSessionDevice,
  useSessionUser,
} from "@hornet-web-react/core/contexts/session"
import { CONTAINER_WULL_WIDTH } from "../../styles/theme"
import NavBar from "./NavBar/NavBar"
import { useQuickiesNavigation } from "../../hooks/use-quickies-navigation"
import IconButton from "@hornet-web-react/core/components/UI/IconButton"
import { appUrl } from "../../config"
import { FaShare } from "react-icons/fa"
import { useHasShareCapability } from "@hornet-web-react/core/hooks/use-has-share-capability"
import Burger from "./NavBar/Burger"
import NiceModal from "@ebay/nice-modal-react"
import { useEventTrackerService } from "../../hooks/use-event-tracker-service"
import QuickiesAnalyticsEvent from "../../models/quickies-analytics-event"
import { getDefaultMeta } from "../../utils/get-default-meta"
import { useQuickiesUserInvites } from "../../hooks/use-quickies-user-invites"
import { QrCodeIcon } from "@heroicons/react/24/solid"
import invariant from "tiny-invariant"

type TopNavBarProps = {
  hideNavBarMenu?: boolean
  rightCornerElement?: ReactNode
}

function TopNavBar({
  hideNavBarMenu = false,
  rightCornerElement,
}: TopNavBarProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { locale, localeRoute } = useSessionDevice()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { isAuthenticated } = useSessionUser()
  const { routeForLanding, routeForHome } = useQuickiesNavigation()
  const { reportEvent } = useEventTrackerService()
  const { data: userInvites } = useQuickiesUserInvites()

  const [logoLink, setLogoLink] = useState("/")

  useEffect(() => {
    // on server it doesn't have query params, on client it does
    // so we need effect here
    setLogoLink(isAuthenticated ? routeForHome : routeForLanding)
  }, [isAuthenticated, routeForHome, routeForLanding])

  const hasShareButton = useHasShareCapability()

  // cannot add `&& isAuthenticated` check here because it means flick of logo
  // position when page loads (it loads generated without session and then it
  // acquires it)
  const hasNavBar = !hideNavBarMenu

  const handleShareOnClick = async () => {
    try {
      const url = `${appUrl}${localeRoute}?utm_source=hornetx&utm_medium=homepage&utm_campaign=top_share_button`

      const { metaTitle, metaDescription } = getDefaultMeta(t, locale)

      const title = metaTitle
      const text = [metaDescription, url].join("\n\n")

      void reportEvent(QuickiesAnalyticsEvent.navigationTapOnShare())

      // there used to be actual `url` param, but the problem is, that the URL often
      // does not get shared; it basically depends on the "pasting app" (at least on iOS)
      // so in iMessages, it's correct... on Slack, it only shares the title+text and if you
      // hit Copy, it also often copies without URL... so the workaround is to amend the text
      // with the URL and share it as text only (not URL) - also iOS doesn't even load correct
      // favicon for the case when sharing URL, so it's not like we're missing out on much
      await navigator.share({
        title,
        text,
      })
    } catch (err) {
      console.error(err)
    }
  }

  const handleBurgerOnClick = async () => {
    const FeedbackWithLinksModal = (
      await import("../Modals/FeedbackWithLinksModal")
    ).default

    setIsMenuOpen(true)

    void NiceModal.show(FeedbackWithLinksModal).then(() => {
      setIsMenuOpen(false)
    })
  }

  const handleShareViaInviteCodeOnClick = async () => {
    invariant(userInvites, "User invites must be loaded")

    const UserInvitesModal = (await import("../Modals/UserInvitesModal"))
      .default

    void NiceModal.show(UserInvitesModal, {
      userInvite: userInvites,
    })
  }

  let topRightCornerElement = null

  // goes by priority
  switch (true) {
    case rightCornerElement !== undefined:
      topRightCornerElement = (
        <TopRightCornerElementWrapper>
          {rightCornerElement}
        </TopRightCornerElementWrapper>
      )
      break

    case !!userInvites?.quickies_invite.invite_code:
      topRightCornerElement = (
        <ShareViaInviteCodeButton>
          <IconButton
            type={"button"}
            size={"small"}
            onClick={handleShareViaInviteCodeOnClick}
          >
            <QrCodeIcon height={24} color={theme.color.text.primary} />
          </IconButton>
        </ShareViaInviteCodeButton>
      )
      break

    case hasShareButton:
      topRightCornerElement = (
        <ShareButton>
          <IconButton
            type={"button"}
            size={"small"}
            onClick={handleShareOnClick}
          >
            <FaShare size={20} color={theme.color.tint.default} />
          </IconButton>
        </ShareButton>
      )
      break
  }

  return (
    <div>
      <Wrapper>
        {/* pretend we're authenticated to prevent the flick */}
        <TopNavBarContainer isAuthenticated={true}>
          <BurgerButton isOpen={isMenuOpen} onClick={handleBurgerOnClick} />

          <Content hasNavBar={hasNavBar}>
            <Logo href={logoLink}>
              <QuickiesLogo
                alt={t("quickies:alt.quickies_logo")}
                height={24}
                data-test={"webAppLogo"}
              />
            </Logo>

            {hasNavBar ? <StretchedNavBar /> : null}

            {topRightCornerElement}
          </Content>
        </TopNavBarContainer>
      </Wrapper>
      <BarWithShadow />
    </div>
  )
}

export default TopNavBar

const Wrapper = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.height.topNavBar};
  background: ${({ theme }) => theme.color.bg.topNavBar};
  color: ${({ theme }) => theme.color.text.primary};
  z-index: 300;
  position: fixed;
`

const BarWithShadow = styled(Wrapper)`
  box-shadow: 0px 4px 4px 0px #0e0c1b4d;
  z-index: 100;
`

const TopNavBarContainer = styled(Container)<{
  isAuthenticated: boolean
}>`
  height: ${({ theme }) => theme.height.topNavBar};
  position: relative;
  z-index: 200;

  @media screen and ${({ theme }) =>
      theme.breakpoints.mediaQueryHasVisibleLeftPanel} {
    max-width: ${({ theme, isAuthenticated }) =>
      isAuthenticated
        ? theme.breakpoints.size.sm
        : theme.width.containerMaxWidthMobile}px;
    padding: 0;
  }

  @media screen and ${({ theme }) =>
      theme.breakpoints.mediaQueryHasVisibleRightPanel} {
    max-width: ${({ theme, isAuthenticated }) =>
      isAuthenticated
        ? CONTAINER_WULL_WIDTH + 32
        : theme.width
            .containerMaxWidthMobile}px; // some extra space so the logo looks better above the menu
  }
`

const Content = styled.div<{
  hasNavBar: boolean
}>`
  height: 100%;
  flex: 1 1 auto;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.color.bg.topNavBar};
  position: relative;

  @media screen and ${({ theme }) =>
      theme.breakpoints.mediaQueryHasVisibleLeftPanel} {
    justify-content: ${({ hasNavBar }) =>
      hasNavBar ? "space-between" : "center"};
  }
`

const Logo = styled(Link)`
  display: block;
  position: relative;
`

const ShareButton = styled.div`
  display: block;
  position: absolute;
  top: 11px;
  right: 0;

  @media screen and ${({ theme }) =>
      theme.breakpoints.mediaQueryHasVisibleLeftPanel} {
    display: none;
  }
`

const TopRightCornerElementWrapper = styled.div`
  display: block;
  position: absolute;
  top: 7px;
  right: 0;
`

const StretchedNavBar = styled(NavBar)`
  min-width: 220px;
  display: none;

  @media screen and ${({ theme }) =>
      theme.breakpoints.mediaQueryHasVisibleLeftPanel} {
    display: flex;
  }
`

const BurgerButton = styled(Burger)`
  position: absolute;
  top: 9px;
  left: ${({ theme }) => theme.spacing.regular};
  z-index: 400;
  display: flex;

  @media screen and ${({ theme }) =>
      theme.breakpoints.mediaQueryHasVisibleLeftPanel} {
    display: none;
  }
`

const ShareViaInviteCodeButton = styled.div`
  display: block;
  position: absolute;
  top: 11px;
  right: 0;
`
