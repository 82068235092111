let locales = [
  "de",
  "de-DE",
  "uk",
  "uk-UA",
  "en",
  "id",
  "zh-TW",
  "zh",
  "th",
  "tr",
]

if (
  process.env.NEXT_PUBLIC_APP_ENV === "development" ||
  process.env.NEXT_PUBLIC_APP_ENV === "alpha"
) {
  locales = ["cs-CZ", ...locales]
}

module.exports = locales
