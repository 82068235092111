import { useCallback } from "react"
import { format } from "date-fns"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import LocalStorageService, {
  StorageKey,
} from "@hornet-web-react/core/services/LocalStorageService"
import { CORE_TYPES } from "@hornet-web-react/core/services/types"
import { useSWRConfig } from "swr"
import useSWRImmutable from "swr/immutable"
import { cyrb53 } from "@hornet-web-react/core/utils/cyrb53"
import { useQuickiesSession } from "./use-quickies-session"
import NiceModal from "@ebay/nice-modal-react"
import { useEventTrackerService } from "./use-event-tracker-service"
import QuickiesAnalyticsEvent from "../models/quickies-analytics-event"

import { QuickiesProfileId } from "@hornet-web-react/core/types"

export const QUICKIES_PROFILES_VIEW = 3

type ProfileViewsCountState = {
  date: string
  pIds: string[] // hashes
}

const SWR_KEY = "/_services/local_storage/quickies_profile_views"

export function useQuickiesProfileViewsCounter() {
  const today = format(new Date(), "Y-MM-d")

  const { data: quickiesSession } = useQuickiesSession()
  const localStorageService = useCoreService<LocalStorageService>(
    CORE_TYPES.LocalStorageService
  )
  const { reportEvent } = useEventTrackerService()

  const localStorageFetcher = async () => {
    return localStorageService.getItem<ProfileViewsCountState>(
      StorageKey.quickiesProfileViews
    )
  }

  const { mutate } = useSWRConfig()
  const { data } = useSWRImmutable(SWR_KEY, localStorageFetcher)

  return {
    hasReachedLimit: useCallback(
      (profileId: QuickiesProfileId) => {
        // invalid data
        if (!data || data.date !== today) {
          return false
        }

        if (quickiesSession?.profile.hasCustomProfilePhoto === true) {
          return false
        }

        // hasn't reached the limit
        if (data.pIds.length < QUICKIES_PROFILES_VIEW) {
          return false
        }

        const profileIdHash = cyrb53(profileId)

        // if limit is reached, check if that profile id is in the list
        // and allow it
        return data.pIds.indexOf(profileIdHash) === -1
      },
      [data, quickiesSession?.profile.hasCustomProfilePhoto, today]
    ),

    clearCounter: useCallback(() => {
      localStorageService.setItem(StorageKey.quickiesProfileViews, {
        date: today,
        pIds: [],
      })
      void mutate(SWR_KEY)
    }, [localStorageService, mutate, today]),

    countProfileView: useCallback(
      (profileId: QuickiesProfileId) => {
        const profileIdHash = cyrb53(profileId)

        if (quickiesSession?.profile.hasCustomProfilePhoto === true) {
          return
        }

        // starting fresh or it's a new day
        if (!data || data.date !== today) {
          localStorageService.setItem(StorageKey.quickiesProfileViews, {
            date: today,
            pIds: [profileIdHash],
          })
          void mutate(SWR_KEY)
          return
        }

        // simply add the profileId to the list if it's not there already
        if (data.pIds.indexOf(profileIdHash) === -1) {
          localStorageService.setItem(StorageKey.quickiesProfileViews, {
            date: today,
            pIds: [...data.pIds, profileIdHash],
          })
          void mutate(SWR_KEY)
        }
      },
      [
        quickiesSession?.profile.hasCustomProfilePhoto,
        data,
        today,
        localStorageService,
        mutate,
      ]
    ),

    openReachedProfileLimitModal: useCallback(async () => {
      const ReachedProfileLimitModal = (
        await import("../components/Modals/ReachedProfileLimitModal")
      ).default

      void reportEvent(QuickiesAnalyticsEvent.profileLimitReached())

      void NiceModal.show(ReachedProfileLimitModal, {
        //
      })
    }, [reportEvent]),
  }
}
