import { z } from "zod"
import { MemberOnlineStatus } from "@hornet-web-react/core/models/member.model"
import {
  HornetProfileId,
  QuickiesProfileId,
} from "@hornet-web-react/core/types"
import { printZodValidationError } from "@hornet-web-react/core/utils/print-zod-validation-error"
import { v4 as uuidv4 } from "uuid"

export const QuickiesMapMemberApiPayload = z.object({
  quickies_member: z.object({
    id: QuickiesProfileId,
    hornet_x_profile_id: HornetProfileId,
    anonymous: z.boolean(),
    lat: z.number(),
    lng: z.number(),
    quickies_profile_photo: z.object({
      thumbnail_url: z.string().url(),
    }),
    online: z.boolean(),
    last_online: z.coerce.date().nullable(),
  }),
})
export type QuickiesMapMemberApiPayload = z.infer<
  typeof QuickiesMapMemberApiPayload
>

export const QuickiesMapMembersApiPayload = z.object({
  quickies_members: z.array(QuickiesMapMemberApiPayload),
})
export type QuickiesMapMembersApiPayload = z.infer<
  typeof QuickiesMapMembersApiPayload
>

export const QuickiesMapDummyMemberApiPayload = z.object({
  quickies_member: z.object({
    id: z.undefined(),
    lat: z.number(),
    lng: z.number(),
    quickies_profile_photo: z.object({
      thumbnail_url: z.string().url(),
    }),
  }),
})
export type QuickiesMapDummyMemberApiPayload = z.infer<
  typeof QuickiesMapDummyMemberApiPayload
>

export const QuickiesMapDummyMembersApiPayload = z.object({
  quickies_members: z.array(QuickiesMapDummyMemberApiPayload),
})
export type QuickiesMapDummyMembersApiPayload = z.infer<
  typeof QuickiesMapDummyMembersApiPayload
>

export const createQuickiesMapMemberModel = (
  payload: QuickiesMapMemberApiPayload,
  currentUserProfileId?: string
): QuickiesMapMemberModel | undefined => {
  const model = QuickiesMapMemberApiPayload.safeParse(payload)
  if (model.success) {
    return new QuickiesMapMemberModel(
      model.data,
      currentUserProfileId === model.data.quickies_member.hornet_x_profile_id
    )
  }

  payload && printZodValidationError(model.error)
}

export function isQuickiesMapMemberApiPayload(
  payload: QuickiesMapMemberApiPayload | QuickiesMapDummyMemberApiPayload
): payload is QuickiesMapMemberApiPayload {
  return payload.quickies_member.id !== undefined
}

export const createQuickiesMapDummyMemberModel = (
  payload: QuickiesMapDummyMemberApiPayload
) => {
  const model = QuickiesMapDummyMemberApiPayload.safeParse(payload)
  if (model.success) {
    return new QuickiesMapDummyMemberModel(model.data)
  }

  payload && printZodValidationError(model.error)
}

// export interface only and keep the model private
export type QuickiesMapMember = {
  profileId: QuickiesProfileId
  profilePhotoUrl: string
  isCurrentUser: boolean
  lat: number
  lng: number
}

class QuickiesMapDummyMemberModel implements QuickiesMapMember {
  private readonly member: QuickiesMapDummyMemberApiPayload["quickies_member"]
  private readonly dummyprofileId: QuickiesProfileId

  constructor(payload: QuickiesMapDummyMemberApiPayload) {
    this.member = payload.quickies_member
    this.dummyprofileId = QuickiesProfileId.parse(uuidv4())
  }

  get profileId() {
    return this.dummyprofileId
  }

  get profilePhotoUrl(): string {
    return this.member.quickies_profile_photo.thumbnail_url
  }

  get lat(): number {
    return this.member.lat
  }

  get lng(): number {
    return this.member.lng
  }

  get isCurrentUser() {
    return false
  }
}

class QuickiesMapMemberModel implements QuickiesMapMember {
  private readonly member: QuickiesMapMemberApiPayload["quickies_member"]
  readonly isCurrentUser: boolean

  constructor(payload: QuickiesMapMemberApiPayload, isCurrentUser: boolean) {
    this.member = payload.quickies_member
    this.isCurrentUser = isCurrentUser
  }

  get profilePhotoUrl(): string {
    return this.member.quickies_profile_photo.thumbnail_url
  }

  get profileId(): QuickiesProfileId {
    return this.member.id
  }

  get hornetProfileId(): HornetProfileId {
    return this.member.hornet_x_profile_id
  }

  get onlineStatus(): MemberOnlineStatus {
    return {
      isOnline: this.member.online,
      lastOnline: this.member.last_online,
    }
  }

  get lat(): number {
    return this.member.lat
  }

  get lng(): number {
    return this.member.lng
  }

  get isAnonymous() {
    return this.member.anonymous
  }
}
