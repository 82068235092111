import { FC } from "react"
import { BlackButton } from "@hornet-web-react/core/components/UI/TextButton"
import QuickiesAnalyticsEvent from "../../models/quickies-analytics-event"
import NiceModal from "@ebay/nice-modal-react"
import { UserSignedUpOrigin } from "@hornet-web-react/login/models/login-analytics-event"
import useTranslation from "next-translate/useTranslation"
import { useQuickiesCookieConsent } from "../../hooks/use-quickies-cookie-consent"
import { useEventTrackerService } from "../../hooks/use-event-tracker-service"
import { useEffectOnce } from "react-use"

type G7LoginButtonProps = {
  redirectTo: string | null
  userSignedUpOrigin: UserSignedUpOrigin
  size?: "default" | "small"
}

const i18nKey = `quickies:components.layout.g7_login_button`

const G7LoginButton: FC<G7LoginButtonProps> = ({
  redirectTo,
  userSignedUpOrigin,
  size = "default",
}) => {
  const { t } = useTranslation()
  const { handleCookieConsentModal } = useQuickiesCookieConsent()
  const { reportEvent } = useEventTrackerService()

  useEffectOnce(() => {
    // preload the modal
    if ("requestIdleCallback" in window) {
      window.requestIdleCallback(async () => import("../Modals/LoginG7Modal"))
    }
  })

  const handleLoginOnClick = async () => {
    void reportEvent(QuickiesAnalyticsEvent.landingTapOnLoginCta())

    await handleCookieConsentModal()

    const LoginG7Modal = (await import("../Modals/LoginG7Modal")).default
    void NiceModal.show(LoginG7Modal, {
      redirectTo,
      userSignedUpOrigin,
    })
  }

  return (
    <BlackButton size={size} onClick={() => handleLoginOnClick()}>
      {t(`${i18nKey}.label`)}
    </BlackButton>
  )
}

export default G7LoginButton
