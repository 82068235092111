import { useCookieConsent } from "@hornet-web-react/core/hooks/use-cookie-consent"
import { useCallback, useEffect } from "react"
import NiceModal from "@ebay/nice-modal-react"

export const useQuickiesCookieConsent = () => {
  const props = useCookieConsent()

  // preload the privacy manager modal if it will be needed
  useEffect(() => {
    if (!props.isLoading && !props.isSet && "requestIdleCallback" in window) {
      window.requestIdleCallback(
        async () => import("../components/Modals/PrivacyManagerModal")
      )
    }
  }, [props.isLoading, props.isSet])

  return {
    ...props,

    handleCookieConsentModal: useCallback(async () => {
      if (!props.isSet) {
        return showPrivacyManagerModal()
      }

      return Promise.resolve()
    }, [props.isSet]),
  }
}

export const showPrivacyManagerModal = async () => {
  const PrivacyManagerModal = (
    await import("../components/Modals/PrivacyManagerModal")
  ).default

  return NiceModal.show(PrivacyManagerModal)
}
