import { useCallback, useRef, useState } from "react"
import {
  debug,
  isClient,
  isRight,
  unwrapEither,
} from "@hornet-web-react/core/utils"
import { useApi } from "@hornet-web-react/core/hooks/use-api"
import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import WebsocketService, {
  MessageListenerCallback,
} from "@hornet-web-react/core/services/WebsocketService"
import { CORE_TYPES } from "@hornet-web-react/core/services/types"
import { z } from "zod"
import {
  createQuickiesMapMemberModel,
  QuickiesMapMember,
  QuickiesMapMemberApiPayload,
} from "../models/quickies-map-member.model"
import { ProfileId } from "@hornet-web-react/core/types/session"
import {
  useSessionDevice,
  useSessionUser,
} from "@hornet-web-react/core/contexts/session"
import { useQuickiesSession } from "./use-quickies-session"

let timeoutRef: number | null = null
let timeoutReady = true
let isOnline = false

const QuickiesProfileOnlineApiPayload = z.object({
  data: QuickiesMapMemberApiPayload,
})
type QuickiesProfileOnlineApiPayload = z.infer<
  typeof QuickiesProfileOnlineApiPayload
>

const QuickiesProfileOfflineApiPayload = z.object({
  data: QuickiesMapMemberApiPayload,
})
type QuickiesProfileOfflineApiPayload = z.infer<
  typeof QuickiesProfileOfflineApiPayload
>

type AddedMembers = QuickiesMapMember[]

export const useQuickiesRealtimeProfiles = () => {
  const { deviceLocation } = useSessionDevice()
  const { currentUser } = useSessionUser()
  const { makeApiRequest, getEndpoint } = useApi()
  const websocketService = useCoreService<WebsocketService>(
    CORE_TYPES.WebsocketService
  )
  const { data: quickiesSession, hasActiveQuickiesProfile } =
    useQuickiesSession()
  const canMarkAsOnlineOrOffline = !!quickiesSession && hasActiveQuickiesProfile

  const [addedMembers, setAddedMembers] = useState<AddedMembers>([])
  const [removedMemberIds, setRemovedMemberIds] = useState<ProfileId[]>([])

  const onlineRequest = useCallback(async () => {
    if (!timeoutReady || !canMarkAsOnlineOrOffline) {
      return
    }

    const apiResult = await makeApiRequest<{
      next_set_online_seconds: number
    }>(getEndpoint(ApiServiceEndpoint.QuickiesProfileOnlinePut))

    if (isRight(apiResult)) {
      isOnline = true
      const response = unwrapEither(apiResult)

      timeoutRef = window.setTimeout(() => {
        void onlineRequest()
      }, response.next_set_online_seconds * 1000)
    }
  }, [getEndpoint, makeApiRequest, canMarkAsOnlineOrOffline])

  const offlineRequest = useCallback(async () => {
    if (!isOnline || !canMarkAsOnlineOrOffline) {
      return
    }

    // ignore result
    await makeApiRequest(
      getEndpoint(ApiServiceEndpoint.QuickiesProfileOfflinePut)
    )
  }, [getEndpoint, makeApiRequest, canMarkAsOnlineOrOffline])

  const quickiesProfileOnlineListener = (
    payload: QuickiesProfileOnlineApiPayload
  ) => {
    const messagePayload = QuickiesProfileOnlineApiPayload.parse(payload)

    const memberModel = createQuickiesMapMemberModel(
      messagePayload.data,
      currentUser?.profileId
    )
    if (memberModel) {
      setAddedMembers((prev) => {
        return [
          ...prev.filter(
            (member) => member.profileId !== memberModel.profileId
          ),
          memberModel,
        ]
      })
    }
  }

  const quickiesProfileOfflineListener = (
    payload: QuickiesProfileOfflineApiPayload
  ) => {
    // no-op
    // const messagePayload = QuickiesProfileOfflineApiPayload.parse(payload)
    //
    // const memberModel = QuickiesMapMemberApiPayload.safeParse(payload.data)
    // if (memberModel.success) {
    //   const removedMember = createQuickiesMapMemberModel(memberModel.data, currentUser?.profileId)
    //   // FIX: this removes the member from the map for good - they won't reappear
    //   // we want to keep the map populated, so it's not even a feature we want to do
    //   // but if you ever want to do this, you would need to then remove the ID when the
    //   // user comes back online
    //   // setRemovedMemberIds((prev) => [...prev, removedMember.profileId])
    // }
  }

  const handleQuickiesProfileOnline = useRef<
    MessageListenerCallback<QuickiesProfileOnlineApiPayload>
  >(quickiesProfileOnlineListener)
  handleQuickiesProfileOnline.current = quickiesProfileOnlineListener

  const handleQuickiesProfileOffline = useRef<
    MessageListenerCallback<QuickiesProfileOfflineApiPayload>
  >(quickiesProfileOfflineListener)
  handleQuickiesProfileOffline.current = quickiesProfileOfflineListener

  return {
    markAsOnline: useCallback(async () => {
      if (!isClient || !deviceLocation) {
        return
      }

      if (timeoutRef !== null) {
        return
      }

      timeoutReady = true
      return onlineRequest()
    }, [onlineRequest, deviceLocation]),

    markAsOffline: useCallback(async () => {
      if (!isClient || !deviceLocation) {
        return
      }

      timeoutReady = false

      // kill the interval, post offline status
      if (timeoutRef !== null) {
        clearTimeout(timeoutRef)
        timeoutRef = null
      }

      return offlineRequest()
    }, [offlineRequest, deviceLocation]),

    subscribe: useCallback(() => {
      debug("useQuickiesRealtimeProfiles.subscribe")

      websocketService.addMessageListener<QuickiesProfileOnlineApiPayload>(
        "realtimeProfiles:handleQuickiesProfileOnline",
        "user/{profileId}/quickies/profile_online",
        handleQuickiesProfileOnline
      )

      websocketService.addMessageListener<QuickiesProfileOfflineApiPayload>(
        "realtimeProfiles:handleQuickiesProfileOffline",
        "user/{profileId}/quickies/profile_offline",
        handleQuickiesProfileOffline
      )
    }, [websocketService]),

    unsubscribe: useCallback(() => {
      debug("useQuickiesRealtimeProfiles.unsubscribe")

      websocketService.removeMessageListener(
        "realtimeProfiles:handleQuickiesProfileOnline"
      )
      websocketService.removeMessageListener(
        "realtimeProfiles:handleQuickiesProfileOffline"
      )
    }, [websocketService]),

    addedMembers,
    removedMemberIds,
  }
}
