import appConfig from "./config"
import { AppConfig } from "@hornet-web-react/core/services/AppConfig"
import { Country } from "@hornet-web-react/core/types/session"

type QuickiesAppConfig = AppConfig & {
  aws: {
    region: string
    cognito: {
      identityPoolId: string
    }
    chatUpload: {
      bucket: string
    }
  }
}

export default appConfig as QuickiesAppConfig

const unwrappedCountry = Country.unwrap()

export const ALLOWED_COUNTRY_CODES: Country[] = [
  unwrappedCountry.enum.de,
  unwrappedCountry.enum.ua,
  unwrappedCountry.enum.cz,
  unwrappedCountry.enum.id,
  unwrappedCountry.enum.tw,
  unwrappedCountry.enum.th,
  unwrappedCountry.enum.tr,
]
