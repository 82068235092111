import appConfig, { ALLOWED_COUNTRY_CODES } from "../app-config"
import { IS_CUSTOM_GPS_COOKIE_NAME } from "./is-custom-gps"
import { Country, Locale } from "@hornet-web-react/core/types/session"

export function isCountryCodeAllowed(
  countryCode: string,
  getCookie: (cookieName: string) => string | undefined
) {
  // first, the overrides: cookie for sudo, custom gps, and dev environments
  if (getCookie("is_sudo") === "y") {
    return true
  }

  if (getCookie(IS_CUSTOM_GPS_COOKIE_NAME)?.split(",").length === 2) {
    return true
  }

  // wrote it like fn so it's easier to comment/uncomment the Alpha env
  function isOverriddenByEnv() {
    return (
      appConfig.environment === "alpha" ||
      appConfig.environment === "development"
    )
  }

  if (isOverriddenByEnv()) {
    return true
  }

  // then the default: only allow certain countries
  const parsedCountry = Country.safeParse(countryCode.toLowerCase())
  if (!parsedCountry.success) {
    return false
  }

  return (
    parsedCountry.success && ALLOWED_COUNTRY_CODES.includes(parsedCountry.data)
  )
}

export const countryCheckIgnorePathnames = [
  "/uh-oh",
  "/404",
  "/terms-of-service",
  "/privacy-policy",
  "/guidelines",
  "/impressum",
  "/verify/ipq",
  "/unavailable",
  "/debug",
]

const unwrappedCountry = Country.unwrap()

export const countryToLocaleMap: [Country, Locale][] = [
  [unwrappedCountry.enum.de, Locale.enum.de],
  [unwrappedCountry.enum.ua, Locale.enum.uk],
  [unwrappedCountry.enum.id, Locale.enum.id],
  [unwrappedCountry.enum.tw, Locale.enum.zh],
  [unwrappedCountry.enum.th, Locale.enum.th],
  [unwrappedCountry.enum.tr, Locale.enum.tr],
]

export const getLocaleForCountry = (country: Country): Locale => {
  const found = countryToLocaleMap.find(([c]) => c === country)
  return found ? found[1] : Locale.enum.en
}
