import { z } from "zod"
import { identity, sortBy } from "ramda"
import { sanitize } from "@hornet-web-react/core/utils/sanitize"
import { LookupDataItem } from "./lookup-data.model"
import {
  convertMetricHeightToImperial,
  convertMetricWeightToImperial,
} from "@hornet-web-react/core/utils/metric-to-imperial"
import { differenceInMonths, format } from "date-fns"
import MemberPhotoModel, {
  createMemberPhotoModel,
  MemberPhotoApiPayload,
} from "./member-photo.model"
import { HornetProfileId } from "@hornet-web-react/core/types"

export const MemberPrivatePhotoAccess = z.enum([
  "none",
  "granted",
  "pending",
  "rejected",
])
export type MemberPrivatePhotoAccess = z.infer<typeof MemberPrivatePhotoAccess>

export const MemberCommunityBadgeApiPayload = z.object({
  community_badge: z.object({
    name: z.string(),
    program_name: z.string().nullable(), // could be deleted
    image_url: z.string().url(),
    description: z.string(),
    level: z.number(),
    community_url: z.string().url(),
    community_portal_id: z.coerce.string(),
  }),
})
export type MemberCommunityBadgeApiPayload = z.infer<
  typeof MemberCommunityBadgeApiPayload
>

export const HashtagApiPayload = z.object({
  hashtag: z.object({
    title: z.string(),
  }),
})
export type HashtagApiPayload = z.infer<typeof HashtagApiPayload>

export const KnowYourStatusApiPayload = z
  .object({
    last_tested: z.coerce.date(),
    hiv_status: LookupDataItem.nullable(),
  })
  .nullable()
export type KnowYourStatusApiPayload = z.infer<typeof KnowYourStatusApiPayload>

export const MemberApiPayload = z.object({
  member: z.object({
    current_country_code: z.string().nullable().optional(), // for my session only
    id: HornetProfileId,
    display_name: z.string().nullable(),
    headline: z.string().nullable(),
    about_you: z.string().nullable(),
    age: z.number().nullable(),
    height: z.number().nullable(),
    weight: z.number().nullable(),
    note: z.string().nullable().optional(),
    distance: z.number().nullable().optional(),
    show_distance: z.boolean(),
    preferred_language: z.string().nullable(),
    location: z.string().nullable(),
    bio: z.string().nullable(),
    explorer: z.boolean(),
    online: z.boolean(),
    crowned: z.boolean(),
    favourite: z.boolean().optional(),
    status_icon: z.string(),
    recent_hearts_sent: z.number(),
    visible: z.boolean(),
    system_profile: z.boolean(),
    broadcast_profile: z.boolean(),
    show_onboarding: z.boolean().optional(),
    interests: z.object({
      hashtags: z.array(HashtagApiPayload),
    }),
    created_at: z.coerce.date(),
    account: z.object({
      username: z.string(),
      username_claimed: z.boolean(),
      public: z.boolean(),
    }),
    relationship: LookupDataItem.nullable(),
    ethnicity: LookupDataItem.nullable(),
    identity: LookupDataItem.nullable(),
    unit_of_measure: LookupDataItem,
    gender: LookupDataItem.nullable(),
    sexuality: LookupDataItem.nullable(),
    pronouns: LookupDataItem.nullable(),
    looking_fors: z.array(LookupDataItem),
    city: z.string().nullable(),
    know_your_status: KnowYourStatusApiPayload.optional(),

    gallery: z.object({
      // The number of additional photos the user has, above and beyond the 6 gallery preview photos.
      count: z.number(),

      // Only returns the thumbnail versions.
      previews: z.array(MemberPhotoApiPayload),
    }),

    // The number of public photos the member has (capped to 4)
    public: z.number().optional(),

    // The number of private photos the member has (capped to 4)
    private: z.number().optional(),

    // Will return only public photos if the current user does not have access to member's private photos.
    photos: z.array(MemberPhotoApiPayload),

    community_badges: z.array(MemberCommunityBadgeApiPayload),
    last_online: z.coerce.date().nullable(),

    // Whether the current user can access this member's private photos
    private_photos_accessible: z.boolean(),

    // The status of the current user's request to access photos
    private_photo_access: MemberPrivatePhotoAccess.optional(),

    // The status of the member's request to access the current user's photos
    my_private_photos_access: MemberPrivatePhotoAccess.optional(),

    spaces_count: z.number().optional(),
    public_user_video_feed_entry_id: z.number().nullable().optional(),
    // followers?: [] // TODO: Members: fix
    followers_count: z.number(),
    followed_count: z.number(),
    posts_count: z.number(),
    verification_level: z.number(),
    broadcast_started_at: z.coerce.date().nullable(),
    social_information: z
      .object({
        instagram_handle: z.string().nullable().optional(),
      })
      .optional(),
  }),
})

export type MemberApiPayload = z.infer<typeof MemberApiPayload>

export const MemberOnlineStatus = z.object({
  isOnline: z.boolean(),
  lastOnline: z.coerce.date().nullable(),
})

export type MemberOnlineStatus = z.infer<typeof MemberOnlineStatus>

export default class MemberModel {
  private readonly member: MemberApiPayload["member"]
  private readonly isImperialUnitOfMeasure: boolean
  readonly photos: MemberPhotoModel[]

  constructor(payload: MemberApiPayload, isImperialUnitOfMeasure: boolean) {
    this.member = payload.member
    this.photos = sortBy(
      (photo) => photo.slot,
      this.member.photos.map(createMemberPhotoModel)
    )

    this.isImperialUnitOfMeasure = isImperialUnitOfMeasure
  }

  get profilePhoto(): MemberPhotoModel | undefined {
    return this.photos.find((photo) => photo.isPublic)
  }

  get hasProfilePhoto() {
    return Boolean(this.profilePhoto)
  }

  get profileId(): HornetProfileId {
    return this.member.id
  }

  get onlineStatus(): MemberOnlineStatus {
    return { isOnline: this.member.online, lastOnline: this.member.last_online }
  }

  get displayName() {
    return sanitize(this.member.display_name || "The Hornet Guy")
  }

  get displayUsername() {
    return `@${sanitize(this.member.account.username)}`
  }

  get displayNameOrUsername() {
    return this.member.display_name ? this.displayName : this.displayUsername
  }

  get age() {
    return this.member.age
  }

  get username() {
    return sanitize(this.member.account.username)
  }

  get displayNameWithAge() {
    if (!this.age) {
      return this.displayName
    }

    return `${this.displayName}, ${this.age}`
  }

  get displayUsernameWithDistance() {
    if (this.displayDistance) {
      return `${this.displayUsername}, ${this.displayDistance}`
    }

    return this.displayUsername
  }

  get displayDistance() {
    if (!this.member.distance) {
      return ""
    }

    const fractionDigits = this.member.distance > 5 ? 0 : 1

    // calculate km to miles
    if (this.isImperialUnitOfMeasure) {
      return (this.member.distance * 0.621371).toFixed(fractionDigits) + " mi"
    }

    // km
    return this.member.distance.toFixed(fractionDigits) + " km"
  }

  get displayBio() {
    // do not sanitize the output as we want to show chars like `<` and `>`
    return this.member.bio || ""
  }

  get displayLocation() {
    return sanitize(this.member.location || "")
  }

  get displayInstagramHandle() {
    return sanitize(
      this.member.social_information?.instagram_handle || ""
    ).replace("@", "")
  }

  get displayHeight() {
    if (!this.member.height) {
      return ""
    }

    if (this.isImperialUnitOfMeasure) {
      const [feet, inches] = convertMetricHeightToImperial(this.member.height)

      return `${feet}ft ${inches}in`
    }

    return this.member.height + " cm"
  }

  get displayWeight() {
    if (!this.member.weight) {
      return ""
    }

    // this.member.weight is in grams
    const weight = this.member.weight / 1000
    if (this.isImperialUnitOfMeasure) {
      return convertMetricWeightToImperial(weight) + " lbs"
    }

    return weight.toFixed(0) + " kg"
  }

  get hasHornetBadge() {
    return this.member.verification_level > 0
  }

  get followersCount() {
    return this.member.followers_count
  }

  get followingCount() {
    return this.member.followed_count
  }

  get postsCount() {
    return this.member.posts_count
  }

  get infoLookingForText() {
    return this.member.looking_fors
      .map((lookingFor) => lookingFor.title)
      .join(", ")
  }

  get infoPronounsText() {
    const text: (string | undefined)[] = []

    text.push(this.member.gender?.title)
    text.push(this.member.sexuality?.title)
    text.push(this.member.pronouns?.title)

    return text.filter(identity).join(", ")
  }

  get infoIdentityText() {
    const text: (string | undefined)[] = []

    text.push(this.member.ethnicity?.title)
    text.push(this.member.height ? this.displayHeight : undefined)
    text.push(this.member.weight ? this.displayWeight : undefined)
    text.push(this.member.identity?.title)

    return text.filter(identity).join(", ")
  }

  get relationshipTitle() {
    return this.member.relationship?.title || ""
  }

  get knowYourStatusTitle() {
    return this.member.know_your_status?.hiv_status?.title || ""
  }

  get knowYourStatusDate() {
    // maybe show last tested date
    const lastTested = this.member.know_your_status?.last_tested
    const knowYourStatusId = this.member.know_your_status?.hiv_status?.id

    // not tested or decided to hide it
    if (!knowYourStatusId || !lastTested) {
      return undefined
    }

    // not sure (1) = show if it exists
    // positive (3) = never changes, we can show always
    if (knowYourStatusId === "1" || knowYourStatusId === "3") {
      return lastTested
    }

    // negative, negative on prep, positive undetectable + < 6 months
    const monthsAgo = differenceInMonths(new Date(), lastTested)
    if (monthsAgo <= 6) {
      return lastTested
    }

    return undefined
  }

  get knowYourStatusDateFormatted() {
    return this.knowYourStatusDate instanceof Date
      ? format(this.knowYourStatusDate, "do MMM yyyy")
      : undefined
  }

  get createdAt() {
    return this.member.created_at
  }

  get featuredPhotos() {
    return this.photos.filter((photo) => photo.isPublic)
  }

  get privatePhotos() {
    return this.photos.filter((photo) => !photo.isPublic)
  }

  get privatePhotosCount() {
    return this.privatePhotos.length || this.member.private || 0
  }

  get isFavourite() {
    return Boolean(this.member.favourite)
  }

  get communityBadges() {
    return this.member.community_badges
  }

  get isShareable() {
    return this.isPublic && !!this.member.account.username
  }

  get isPublic() {
    return this.member.account.public === true
  }

  get rawBio() {
    return this.member.bio
  }

  get rawUsername() {
    return this.member.account.username
  }

  get rawName() {
    return this.member.display_name
  }

  get shareUrl() {
    return this.member.account.username
      ? `https://hrnt.me/${this.member.account.username}`
      : ""
  }

  get isShowingDistance() {
    return this.member.show_distance
  }

  get lookingForIds() {
    return this.member.looking_fors.map((lookingFor) => lookingFor.id)
  }

  get pronounId() {
    return this.member.pronouns?.id
  }

  get genderId() {
    return this.member.gender?.id
  }

  get sexualityId() {
    return this.member.sexuality?.id
  }

  get ethnicityId() {
    return this.member.ethnicity?.id
  }

  get identityId() {
    return this.member.identity?.id
  }

  get relationshipId() {
    return this.member.relationship?.id
  }

  get knowYourStatusId() {
    return this.member.know_your_status?.hiv_status?.id
  }

  get unitOfMeasureId() {
    return this.member.unit_of_measure.id
  }

  get location() {
    return this.member.location
  }

  get metricHeight() {
    return this.member.height || undefined
  }

  get imperialFeetHeight() {
    if (!this.member.height) {
      return undefined
    }

    const [feet] = convertMetricHeightToImperial(this.member.height)

    return feet
  }

  get imperialInchesHeight() {
    if (!this.member.height) {
      return undefined
    }

    const [, inches] = convertMetricHeightToImperial(this.member.height)

    return inches
  }

  get metricWeight() {
    return this.member.weight
      ? Math.round(this.member.weight / 1000)
      : undefined
  }

  get imperialWeight() {
    return this.metricWeight
      ? convertMetricWeightToImperial(this.metricWeight)
      : undefined
  }

  get isUsernameClaimed() {
    return this.member.account.username_claimed === true
  }

  get instagramHandle() {
    return this.member.social_information?.instagram_handle
  }

  get privatePhotoAccess() {
    return this.member.private_photo_access
  }

  get hasNote() {
    return !!this.member.note
  }

  deletePhoto(photo: MemberPhotoModel) {
    return new MemberModel(
      {
        member: {
          ...this.member,
          photos: this.photos
            .filter((p) => p.id !== photo.id)
            .map((p) => p.serialize()),
        },
      },
      this.isImperialUnitOfMeasure
    )
  }

  setAsProfilePhoto(photo: MemberPhotoModel) {
    const newPhotos = [
      // set the photo as the first one
      photo.setAsProfilePhoto().serialize(),
      // then re-order the rest
      ...this.featuredPhotos
        .filter((p) => p.id !== photo.id)
        .reduce((acc, p, currentIndex) => {
          acc.push(p.changeSlot(currentIndex + 1).serialize())

          return acc
        }, [] as MemberPhotoApiPayload[]),
      // and finally add also private ones
      ...this.privatePhotos.map((p) => p.serialize()),
    ]

    return new MemberModel(
      {
        member: {
          ...this.member,
          photos: newPhotos,
        },
      },
      this.isImperialUnitOfMeasure
    )
  }

  togglePhotoStatus(photo: MemberPhotoModel, newSlot: number) {
    return new MemberModel(
      {
        member: {
          ...this.member,
          photos: this.photos.map((p) => {
            if (p.id === photo.id) {
              return p.toggleStatus(newSlot).serialize()
            }

            return p.serialize()
          }),
        },
      },
      this.isImperialUnitOfMeasure
    )
  }

  serialize() {
    return {
      member: {
        ...this.member,
        photos: this.photos.map((p) => p.serialize()),
      },
    }
  }

  addPhoto(photo: MemberPhotoModel) {
    return new MemberModel(
      {
        member: {
          ...this.member,
          photos: [...this.photos.map((p) => p.serialize()), photo.serialize()],
        },
      },
      this.isImperialUnitOfMeasure
    )
  }

  setIsFavourite(isFavourite: boolean) {
    return new MemberModel(
      {
        member: {
          ...this.member,
          favourite: isFavourite,
        },
      },
      this.isImperialUnitOfMeasure
    )
  }
}
