import React, { useState } from "react"
import { useRouter } from "next/router"
import styled, { keyframes } from "styled-components"
import { useSessionApi } from "@hornet-web-react/core/contexts/session"

const PageLoader = () => {
  const router = useRouter()
  const {
    route: { setIsRouteTransitioning, pushToRouteHistory },
  } = useSessionApi()
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)

  React.useEffect(() => {
    const handleRouteChange = () => {
      setIsLoaderVisible(true)
      setIsRouteTransitioning(true)
    }

    const handleRouteComplete = (url: string) => {
      setIsLoaderVisible(false)
      setIsRouteTransitioning(false)
      pushToRouteHistory(url)
    }

    // here we subscribe to router change start and complete events
    router.events.on("routeChangeStart", handleRouteChange)
    router.events.on("routeChangeComplete", handleRouteComplete)

    // unsubscribing to router events when component unmounts to prevent memory leaks
    return () => {
      router.events.off("routeChangeStart", handleRouteChange)
      router.events.off("routeChangeComplete", handleRouteComplete)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoaderVisible) {
    return (
      <div
        style={{
          position: "fixed",
          width: "100%",
          top: 0,
          left: 0,
          zIndex: 9999,
          height: 2,
        }}
      >
        <LoadingBar />
      </div>
    )
  }

  return null
}

export default PageLoader

const borealisBar = keyframes`
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }`

const LoadingBar = styled.div`
  position: absolute;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => theme.color.tint.default};
  width: 0;
  animation: ${borealisBar} 2s linear infinite;
`
