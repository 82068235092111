import { FC, ReactNode, useEffect, useRef, useState } from "react"
import Image, { StaticImageData } from "next/image"
import styled, { css } from "styled-components"
import { useIntersectionObserver } from "usehooks-ts"

export type MemberAvatarProps = {
  className?: string
  avatarUrl?: string | StaticImageData | null
  onClick?: () => void
  size?: number
  children?: ReactNode
  borderRadius?: string
  isVanillaMode?: boolean
  isPlaceholderAnimated?: boolean
}

const MemberAvatar: FC<MemberAvatarProps> = ({
  children,
  className,
  onClick,
  size,
  avatarUrl,
  borderRadius,
  isVanillaMode,
  isPlaceholderAnimated = false,
}) => {
  const showImageRef = useRef<HTMLDivElement | null>(null)
  const showImageEntry = useIntersectionObserver(showImageRef, {})
  const [imageUrl, setImageUrl] = useState<string | StaticImageData>("")

  useEffect(() => {
    if (showImageEntry?.isIntersecting) {
      if (avatarUrl) {
        setImageUrl(avatarUrl)
        return
      }

      if (!isPlaceholderAnimated) {
        setImageUrl("/assets/images/missing.png")
      }
    }
  }, [showImageEntry?.isIntersecting, avatarUrl, isPlaceholderAnimated])

  return (
    <Wrapper
      className={className}
      size={size || 40}
      role={onClick ? "button" : "img"}
      ref={showImageRef}
      onClick={onClick}
      hasOnClick={!!onClick}
    >
      {!imageUrl && (
        <Placeholder
          borderRadius={borderRadius}
          width={size || 40}
          height={size || 40}
          isPlaceholderAnimated={isPlaceholderAnimated}
        />
      )}
      {imageUrl && (
        <AvatarWrapper isVanillaMode={isVanillaMode}>
          <AvatarImage
            src={imageUrl}
            alt="Avatar"
            width={size || 40}
            height={size || 40}
          />
        </AvatarWrapper>
      )}
      {children}
    </Wrapper>
  )
}

export default MemberAvatar

const Wrapper = styled.div<{
  size: number
  hasOnClick: boolean
  borderRadius?: string
}>`
  display: flex;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  font-size: 10px; // for alt text
  position: relative;
  cursor: ${(props) => (props.hasOnClick ? "pointer" : "default")};
  border-radius: ${({ borderRadius }) => borderRadius || "100%"};
  overflow: hidden;
  flex-shrink: 0;
`

const placeholderAnimationCss = css`
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #2b2a35;
  background: linear-gradient(to right, #2b2a35 8%, #3e3d48 18%, #2b2a35 33%);
  background-size: 800px 104px;
`

const Placeholder = styled.div<{
  width: number
  height: number
  borderRadius?: string
  isPlaceholderAnimated: boolean
}>`
  background-color: ${({ theme }) => theme.color.tint.disabled};
  flex: 1 1 auto;
  border-radius: ${({ borderRadius }) => borderRadius || "100%"};
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  position: relative;

  ${({ isPlaceholderAnimated }) =>
    isPlaceholderAnimated && placeholderAnimationCss}
`

// TODO: import `vanillaModeImageStyle` once the whole thing is moved out of Quickies
const AvatarWrapper = styled.div<{
  isVanillaMode?: boolean
}>`
  filter: ${({ isVanillaMode }) => (isVanillaMode ? "blur(4px)" : "none")};
  transform: ${({ isVanillaMode }) =>
    isVanillaMode ? "scale(1.1)" : "scale(1)"};
  transition: filter 250ms ease-in-out, transform 250ms ease-in-out;
`

const AvatarImage = styled(Image)<{
  borderRadius?: string
}>`
  display: block;
  flex: 1 1 auto;
  border-radius: ${({ borderRadius }) => borderRadius || "100%"};
`
